<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
};
</script>

<style>
 html, body, #app {
   height: 100vh;
   width: 100vw;
 }
</style>
