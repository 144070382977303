import { LOGIN_TYPE, USER_TYPE } from '../pages/login/constants';

export default {
  state: {
    token: localStorage.getItem('token') || '',
    nickName: localStorage.getItem('nickName') || '',
    userType: localStorage.getItem('userType') || USER_TYPE.ADMIN,
    channelName: localStorage.getItem('channelName') || '',
    storeName: localStorage.getItem('storeName') || '',
    storeId: localStorage.getItem('storeId') || '',
    channelId: localStorage.getItem('channelId') || '',
  },
  getters: {
    logged: (state) => !!state.token,
    channelId: (state) => state.channelId,
    token: (state) => `jwt ${state.token}`,
    userType: (state) => state.userType,
    loginType: (state) => LOGIN_TYPE[state.userType.toUpperCase()],
    nickName: (state) => state.nickName,
    channelName: (state) => state.channelName,
    storeName: (state) => state.storeName,
    storeId: (state) => state.storeId,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token;
      localStorage.setItem('token', token);
    },
    setNickName: (state, nickName) => {
      state.nickName = nickName;
      localStorage.setItem('nickName', nickName);
    },
    setStoreName: (state, storeName) => {
      state.storeName = storeName;
      localStorage.setItem('storeName', storeName);
    },
    setChannelName: (state, channelName) => {
      state.channelName = channelName;
      localStorage.setItem('channelName', channelName);
    },
    setStoreId: (state, storeId) => {
      state.storeId = storeId;
      localStorage.setItem('storeId', storeId);
    },
    removeToken: (state) => {
      state.token = null;
      localStorage.removeItem('token');
    },
    removeNickName: (state) => {
      state.nickName = null;
      localStorage.removeItem('nickName');
    },
    setUserType: (state, userType) => {
      state.userType = userType;
      localStorage.setItem('userType', userType);
    },
    setChannelId: (state, channelId) => {
      state.channelId = channelId;
      localStorage.setItem('channelId', channelId);
    },
  },
};
