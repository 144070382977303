<template>
  <div style="height: 100%;">
    <router-view v-if="!$route.meta.path"/>
    <iframeCommon v-show="showIframe" :path="$route.meta.path"></iframeCommon>
  </div>
</template>

<script>
import iframeCommon from '@/pages/iframe/iframeCommon.vue';

export default {
  name: 'emptyLayout',
  components: {
    iframeCommon,
  },
  computed: {
    showIframe() {
      const { path } = this.$route.meta;
      return !!path;
    },
  },
};
</script>

<style scoped>

</style>
