export default {
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    rowKey: {
      type: String,
    },
    loadData: {
      type: Function,
    },
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    allowedPermissions: {
      type: Array,
      default: () => [],
    },
    indexVisible: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    slotsName() {
      return this.columns
        .filter((column) => !!column.slot?.toString())
        .map((column) => column.slot);
    },
  },
};
