import VueRouter from 'vue-router';

const routes = [
  {
    path: '/login/:userType',
    name: 'login',
    component: () => import('../pages/login'),
  },
  {
    path: '/root',
    name: 'root',
    component: () => import('../layouts/layout'),
  },
  // {
  //   path: '/report',
  //   name: 'report',
  //   meta: {
  //     icon: 'dashboard',
  //     useEmptyLayout: true,
  //   },
  // eslint-disable-next-line max-len
  //   component: () => import('@/pages/account/customer-account-manage/training-report.component.vue'),
  // },
  {
    path: '/report',
    name: 'report',
    meta: {
      icon: 'dashboard',
      useEmptyLayout: true,
    },
    component: () => import('@/pages/train/training-report/report-info.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../pages/not-found'),
  },
];

const routerInstance = new VueRouter({ routes });

export { routes };
export default routerInstance;
