// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios';
import { LOGIN_TYPE, USER_TYPE } from './constants';

class LoginApi {
  static async login(mobile, password, loginType = LOGIN_TYPE[USER_TYPE.ADMIN]) {
    return axios({
      url: `api/${loginType}/account/login`,
      data: { mobile, password },
      method: 'post',
      headers: {
        meta: {
          desc: '登录',
        },
      },
    });
  }

  static async getRouteConfig(loginType = LOGIN_TYPE[USER_TYPE.ADMIN]) {
    return axios({
      url: `/api/${loginType}/permis/tree`,
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取路由配置',
        },
      },
    });
  }
}

export default LoginApi;
