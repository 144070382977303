<template>
  <div class="iframe-content">
    <iframe
    v-if="iframeUrl"
    id="iframe"
    :src="iframeUrl"
    style="height: calc(100%)"
    width="100%"
    frameborder="0">
  </iframe>
  </div>
</template>

<script>

const { VUE_APP_NEW_SITE = '' } = process.env || {};

export default {
  name: 'IframeCommon',
  props: {
    path: {
      type: String,
      default: 'empty',
    },
  },
  created() {
    this.init();
  },
  watch: {
    path(val, old) {
      if (val !== old) {
        this.init();
      }
    },
  },
  computed: {

  },
  data() {
    return {
      token: '',
      iframeUrl: '',
    };
  },
  methods: {
    init() {
      this.token = window.localStorage.getItem('token');
      this.iframeUrl = `${VUE_APP_NEW_SITE}/#/iframe/${this.path}?token=${this.token}&r=${Math.random()}`;
    },
  },

};
</script>

<style lang="less" scoped>
.iframe-content {
  height: 100%;
}
</style>
