<template>
  <div class="pagination">
    <Page
      show-total
      :total="pageConfig.count"
      :current="pageConfig.page"
      :page-size="pageConfig.limit"
      :page-size-opts="pageConfig.pageSizeOptions || PAGE_SIZE_OPTS"
      @on-change="onChange"
      @on-page-size-change="onPageSizeChange" />
  </div>
</template>

<script>
import mixin from './mixin';
import { PAGE_SIZE_OPTS } from './constants';

export default {
  name: 'app-pagination',
  mixins: [mixin],
  model: {
    prop: 'pageConfig',
    event: 'onChange',
  },
  data() {
    return {
      PAGE_SIZE_OPTS,
    };
  },
  methods: {
    onChange(current) {
      const configCopy = { ...this.pageConfig };
      configCopy.page = current;
      this.$emit('onChange', configCopy);
    },
    onPageSizeChange(pageSize) {
      const configCopy = { ...this.pageConfig };
      configCopy.limit = pageSize;
      this.$emit('onChange', configCopy);
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
