import { render, staticRenderFns } from "./empty-layout.vue?vue&type=template&id=a6964418&scoped=true&"
import script from "./empty-layout.vue?vue&type=script&lang=js&"
export * from "./empty-layout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6964418",
  null
  
)

export default component.exports