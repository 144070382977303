// eslint-disable-next-line import/no-extraneous-dependencies
import NProgress from 'nprogress';
import '@/plugins/vuex';
import store from '@/stores/store';
import RouterUtil from '../utils/router.util';

const { VUE_APP_LOGIN_TYPE } = process.env || {};

NProgress.configure({ showSpinner: false });
let skip404 = false;

/**
 * 开始跳转
 * @param to
 * @param from
 * @param next
 */
function progressStart(to, from, next) {
  skip404 = false;
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  next();
}

function loggedValidation(to, from, next) {
  if (!store.getters.logged && to.name !== 'login') {
    const { userType } = store.getters;
    next(`/login/${VUE_APP_LOGIN_TYPE === 'PARAM' ? userType : 'auto'}`);
    return;
  }

  next();
}

async function authTreeLoader(to, from, next) {
  if (store.getters.logged && !store.getters.authTreeLoaded) {
    await store.dispatch('setAuthTree');
    skip404 = true;
    const timer = setTimeout(() => {
      next(to.fullPath);
      clearTimeout(timer);
    });
  }

  if (to.fullPath === '/') {
    next(RouterUtil.getDefaultRedirectUrl());
    return;
  }

  next();
}

async function autoRedirect(to, from, next) {
  if (!skip404 && to.matched.length === 0) {
    next('/404');
    return;
  }

  next();
}

/**
 * 结束跳转
 */
function progressEnd() {
  NProgress.done();
}

export default {
  beforeEach: [progressStart, loggedValidation, authTreeLoader, autoRedirect],
  afterEach: [progressEnd],
};
