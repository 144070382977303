import Layout from '../layouts/empty-layout';
import { PRODUCT_TYPE } from '../pages/product/device-manage/enum';

export default {
  overview: {
    path: 'overview',
    name: 'overview',
    meta: {
      icon: 'dashboard',
      useEmptyLayout: true,
    },
    component: () => import('@/pages/overview'),
    // component: () => import('@/pages/statistics/activation-of-device'),
  },
  account: {
    path: 'account',
    name: 'account',
    meta: {
      icon: 'account',
    },
    component: Layout,
  },
  product: {
    path: 'product',
    name: 'product',
    meta: {
      icon: 'product',
    },
    component: Layout,
  },
  business: {
    path: 'business',
    name: 'business',
    meta: {
      icon: 'enterprise',
    },
    component: Layout,
  },
  train: {
    path: 'train',
    name: 'train',
    meta: {
      icon: 'train',
    },
    component: Layout,
  },
  order: {
    path: 'order',
    name: 'order',
    meta: {
      icon: 'order',
    },
    component: Layout,
  },
  permission: {
    path: 'permission',
    name: 'permission',
    meta: {
      icon: 'power',
    },
    component: Layout,
  },
  log: {
    path: 'log',
    name: 'log',
    meta: {
      icon: 'log',
    },
    component: Layout,
  },
  stat: {
    path: 'stat',
    name: 'stat',
    meta: {
      icon: 'stores',
    },
    component: Layout,
  },
  system: {
    path: 'system',
    name: 'system',
    meta: {
      icon: 'enterprise',
    },
    component: Layout,
  },
  exam: {
    path: 'exam',
    name: 'exam',
    meta: {
      icon: 'enterprise',
    },
    component: Layout,
  },
  operation: {
    path: 'operation',
    name: 'operation',
    meta: {
      icon: 'enterprise',
    },
    component: Layout,
  },
  'ortho-k': {
    path: 'ortho-k',
    name: 'ortho-k',
    meta: {
      icon: 'enterprise',
    },
    component: Layout,
  },
  'account:consumer': {
    path: 'accountConsumer',
    name: 'accountConsumer',
    component: () => import('@/pages/account/customer-account-manage'),
  },
  // 'account:sgs': {
  //   path: 'accountSgs',
  //   name: 'accountSgs',
  //   component: () => import('@/pages/account/optometrist-account-manage'),
  // },
  'product:p-v3': {
    path: 'productPV3',
    name: 'productPV3',
    component: () => import('@/pages/product/device-manage'),
    meta: {
      type: PRODUCT_TYPE.PV3_QN,
    },
  },
  'product:p-v4': {
    path: 'productPV4',
    name: 'productPV4',
    component: () => import('@/pages/product/device-manage'),
    meta: {
      type: PRODUCT_TYPE.PV4_WM,
    },
  },
  'product:p-v5': {
    path: 'productPV5',
    name: 'productPV5',
    component: () => import('@/pages/product/device-manage'),
    meta: {
      type: PRODUCT_TYPE.PV5_SNX,
    },
  },
  'product:library': {
    path: 'productLibrary',
    name: 'productLibrary',
    component: () => import('@/pages/product/scheme-library'),
  },
  'business:channel': {
    path: 'businessChannel',
    name: 'businessChannel',
    component: () => import('@/pages/business/channel-manage'),
  },
  'business:store': {
    path: 'businessStore',
    name: 'businessStore',
    component: () => import('@/pages/business/store-manage'),
  },
  'train:distribute': {
    path: 'trainDistribute',
    name: 'trainDistribute',
    component: () => import('@/pages/train/training-distribution'),
  },
  'train:record': {
    path: 'trainRecord',
    name: 'trainRecord',
    component: () => import('@/pages/train/training-record'),
  },
  'train:report': {
    path: 'trainReport',
    name: 'trainReport',
    component: () => import('@/pages/train/training-report'),
  },
  'order:product': {
    path: 'orderProduct',
    name: 'orderProduct',
    component: () => import('@/pages/order/device-order-manage'),
  },
  'order:freq-card': {
    path: 'orderDistributeNoManage',
    name: 'orderDistributeNoManage',
    component: () => import('@/pages/order/order-distribute-no-manage'),
  },
  'permission:role': {
    path: 'permissionRole',
    name: 'permissionRole',
    component: () => import('@/pages/permission/role-manage'),
  },
  'permission:sys:account': {
    path: 'permissionSysAccount',
    name: 'permissionSysAccount',
    component: () => import('@/pages/permission/account-manage'),
  },
  'log:customer': {
    path: 'logCustomer',
    name: 'logCustomer',
    component: () => import('@/pages/log/consumer-account-log'),
  },
  'log:user-role-bind': {
    path: 'logUserRoleBind',
    name: 'logUserRoleBind',
    component: () => import('@/pages/log/user-role-bind-log'),
  },
  'log:role': {
    path: 'logRole',
    name: 'logRole',
    component: () => import('@/pages/log/role-manage-log'),
  },
  'log:role-permission-bind': {
    path: 'logRolePermissionBind',
    name: 'logRolePermissionBind',
    component: () => import('@/pages/log/role-permission-bind-log'),
  },
  'log:product': {
    path: 'logProduct',
    name: 'logProduct',
    component: () => import('@/pages/log/device-manage-log'),
  },
  'log:product-distribute': {
    path: 'logProductDistribute',
    name: 'logProductDistribute',
    component: () => import('@/pages/log/device-distribute-log'),
  },
  'log:commonstore': {
    path: 'logCommonStore',
    name: 'logCommonStore',
    component: () => import('@/pages/log/store-bind-log'),
  },
  'log:vcode': {
    path: 'logVerifyCode',
    name: 'logVerifyCode',
    component: () => import('@/pages/log/verification-code-log'),
  },
  'stat:operate': {
    path: 'statOperate',
    name: 'stat0perate',
    component: () => import('@/pages/statistics/operation-condition'),
  },
  'stat:patient': {
    path: 'statPperate',
    name: 'statPperate',
    component: () => import('@/pages/statistics/patient'),
  },
  'stat:product-train': {
    path: 'statProductTrain',
    name: 'statProductTrain',
    component: () => import('@/pages/statistics/spent-of-device'),
  },
  'stat:product-active': {
    path: 'statProductActive',
    name: 'statProductActive',
    component: () => import('@/pages/statistics/activation-of-device'),
  },
  'modify-password': {
    path: 'modifyPassword',
    name: 'modifyPassword',
    meta: {
      icon: 'dashboard',
    },
    component: () => import('@/pages/modify-password'),
  },
  // iframe 新后台
  'app-version': {
    path: 'appVersion',
    name: 'appVersion',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'app-version',
    },
  },
  'pad-version': {
    path: 'padVersion',
    name: 'padVersion',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'pad-version',
    },
  },
  'product:pad:version': {
    path: 'ProductPadVersion',
    name: 'ProductPadVersion',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'product-pad-version',
    },
  },
  'exam:record': {
    path: 'ExamRecord',
    name: 'ExamRecord',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'exam-record',
    },
  },
  'account:sgs': {
    path: 'accountSgs',
    name: 'accountSgs',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'sgs-user',
    },
  },
  'product:control': {
    path: 'ProductControl',
    name: 'ProductControl',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'product-control',
    },
  },
  'common-question': {
    path: 'CommonQuestion',
    name: 'CommonQuestion',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'common-question',
    },
  },
  'customer-daily-check-config': {
    path: 'CustomerDailyCheckConfig',
    name: 'CustomerDailyCheckConfig',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'customer-daily-check-config',
    },
  },
  'customer-point-task': {
    path: 'CustomerPointTask',
    name: 'CustomerPointTask',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'customer-point-task',
    },
  },
  'point-commodity': {
    path: 'PointCommodity',
    name: 'PointCommodity',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'point-commodity',
    },
  },
  'order-point-commodity': {
    path: 'OrderPointCommodity',
    name: 'OrderPointCommodity',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-point-commodity',
    },
  },
  'customer-point': {
    path: 'CustomerPoint',
    name: 'CustomerPoint',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'customer-point',
    },
  },
  'ocr-customer-info': {
    path: 'OcrCustomerInfo',
    name: 'OcrCustomerInfo',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'ocr-customer-info',
    },
  },
  'order-ocr': {
    path: 'OrderOcr',
    name: 'OrderOcr',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-ocr',
    },
  },
  'order-sgs-ocr': {
    path: 'OrderSgsOcr',
    name: 'OrderSgsOcr',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-sgs-ocr',
    },
  },
  'ocr-sgs-user': {
    path: 'OcrSgsUser',
    name: 'OcrSgsUser',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'ocr-sgs-user',
    },
  },
  'ocr-patient-record': {
    path: 'OcrPatientRecord',
    name: 'OcrPatientRecord',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'ocr-patient-record',
    },
  },
  'ocr-customer-info-manual': {
    path: 'OcrCustomerInfoManual',
    name: 'OcrCustomerInfoManual',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'ocr-customer-info-manual',
    },
  },
  'ocr-eye-record': {
    path: 'OcrEyeRecord',
    name: 'OcrEyeRecord',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'ocr-eye-record',
    },
  },
  advert: {
    path: 'Advert',
    name: 'Advert',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'advert',
    },
  },
  'order-time-freq-card': {
    path: 'OrderTimeFreqCard',
    name: 'OrderTimeFreqCard',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-time-freq-card',
    },
  },
  'freq-card-log': {
    path: 'FreqCardLog',
    name: 'FreqCardLog',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'freq-card-log',
    },
  },
  'order-product-lease': {
    path: 'OrderProductLease',
    name: 'OrderProductLease',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-product-lease',
    },
  },
  'website-activity': {
    path: 'WebsiteActivity',
    name: 'WebsiteActivity',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'website-activity',
    },
  },
  'ocr-sgs-vst': {
    path: 'OcrSgsVst',
    name: 'OcrSgsVst',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'ocr-sgs-vst',
    },
  },
  'product-third-license': {
    path: 'ProductThirdLicense',
    name: 'ProductThirdLicense',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'product-third-license',
    },
  },
  'order-customer-jxw-vip': {
    path: 'OrderCustomerJxwVip',
    name: 'OrderCustomerJxwVip',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-customer-jxw-vip',
    },
  },
  'flipper-record': {
    path: 'FlipperRecord',
    name: 'FlipperRecord',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'flipper-record',
    },
  },
  'store-remote-print': {
    path: 'StoreRemotePrint',
    name: 'StoreRemotePrint',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'store-remote-print',
    },
  },
  'order-rental': {
    path: 'OrderRental',
    name: 'OrderRental',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-rental',
    },
  },
  'order-product-rental': {
    path: 'OrderProductRental',
    name: 'OrderProductRental',
    component: () => import('@/pages/iframe/iframeCommon.vue'),
    meta: {
      useEmptyLayout: true,
      path: 'order-product-rental',
    },
  },
};
