import RouterUtil from '../utils/router.util';
// eslint-disable-next-line import/no-cycle
import LoginApi from '../pages/login/login.api';

export default {
  state: {
    authTreeLoaded: false,
    menus: {},
  },
  getters: {
    authTreeLoaded: (state) => state.authTreeLoaded,
    menus: (state) => state.menus,
  },
  mutations: {
    setMenus: (state, menus = {}) => {
      state.menus = menus;
    },
    setAuthTreeLoadState: (state, status) => {
      state.authTreeLoaded = status;
    },
  },
  actions: {
    async setAuthTree(context) {
      // RouterUtil.resetRouter();
      const remoteMenuCfg = await LoginApi.getRouteConfig(context.getters.loginType);
      const modifyPasswd = {
        name: '修改密码',
        permi: 'modify-password',
      };
      const result = remoteMenuCfg.concat([modifyPasswd]);
      RouterUtil.syncRouters(result);
      const menus = RouterUtil.convertMenus(result);
      context.commit('setMenus', menus);
      context.commit('setAuthTreeLoadState', true);
      // const timer = setTimeout(() => {
      //   context.commit('setAuthTreeLoadState', true);
      //   clearTimeout(timer);
      // }, 1000);
    },
  },
};
