export default {
  state: {
    tableLoading: false,
  },
  getters: {
    tableLoading: (state) => state.tableLoading,
  },
  mutations: {
    setTableLoading: (state, tableLoading) => {
      state.tableLoading = tableLoading;
    },
  },
};
