<template>
  <div class="export-bar">
    <div class="content">
      <Button
        @click="onClick('onExportAll')">
        导出所有
      </Button>
      <Button
        @click="onClick('onExport')">
        条件导出
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-export-bar',
  methods: {
    onClick(evtName) {
      this.$emit(evtName);
    },
  },
};
</script>

<style lang="less" scoped>
.export-bar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: @spacingMD;
  .content > *:not(:last-child) {
    margin-right: @spacingMD;
  }
}
</style>
