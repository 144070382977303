<template>
  <div :class="['app-spacing', spacing]">
    <slot />
  </div>
</template>

<script>
import { SPACING } from './constants';

export default {
  name: 'app-spacing',
  props: {
    spacing: {
      type: String,
      default: SPACING.MD,
    },
  },
};
</script>

<style lang="less" scoped>
.app-spacing {
  flex: 1 1 auto;
  display: flex;

  &.sm > *:not(:last-child) {
    margin-right: @spacingSM;
  }
  &.md > *:not(:last-child) {
    margin-right: @spacingMD;
  }
  &.lg > *:not(:last-child) {
    margin-right: @spacingLG;
  }
}
</style>
