export const QUERY_TYPE = {
  INPUT: 'input',
  SELECT: 'select',
  DATE: 'date',
  DATE_RANGE: 'dateRange',
  DATE_TIME: 'dateTime',
  MONTH: 'month',
  INPUT_NUMBER: 'inputNumber',
  SLOT: 'slot',
};

export const DEFAULT_WIDTH = '160px';
