<template>
  <div class="query-bar">
    <div class="main-content">
      <div v-for="item in queryConfig" :key="item.fieldName" class="query-item">
        <span v-if="!item.show && item.name" class="query-title">{{
          item.name
        }}</span>
        <Input
          v-if="!item.type || item.type === QUERY_TYPE.INPUT"
          :style="{ width: item.width || DEFAULT_WIDTH }"
          :value="queryData[item.fieldName]"
          :placeholder="`请输入${item.placeholder || item.name}`"
          @on-change="(e) => onChange(item.fieldName, e.target.value)"
        />
        <el-select
          size="small"
          filterable
          clearable
          v-else-if="item.type === QUERY_TYPE.SELECT && !item.show"
          :style="{ width: item.width || DEFAULT_WIDTH }"
          :value="queryData[item.fieldName]"
          :placeholder="`请选择${item.name}`"
          @change="(value) => onChange(item.fieldName, value)"
        >
          <el-option
            v-for="option in item.options"
            :value="option.value"
            :label="option.label"
            :key="option.value"
          />
        </el-select>
        <el-input-number
          v-else-if="item.type === QUERY_TYPE.INPUT_NUMBER"
          :min="0"
          size="small"
          v-model="queryData[item.fieldName]"
          :style="{ width: item.width || DEFAULT_WIDTH }"
          :value="queryData[item.fieldName]"
          @on-change="(value) => onChange(item.fieldName, value)"
          label="描述文字"
        >
        </el-input-number>
        <DatePicker
          v-else-if="item.type === QUERY_TYPE.DATE_RANGE"
          type="daterange"
          placement="bottom-end"
          :style="{ width: item.width || DEFAULT_WIDTH }"
          :options="item.options"
          :value="queryData[item.fieldName]"
          :placeholder="`请选择${item.name}`"
          @on-change="(values) => onChange(item.fieldName, values)"
        />
        <DatePicker
          v-else-if="item.type === QUERY_TYPE.DATE_TIME"
          type="datetime"
          placement="bottom-end"
          :style="{ width: item.width || DEFAULT_WIDTH }"
          :options="item.options"
          :value="queryData[item.fieldName]"
          :placeholder="`请选择${item.name}`"
          @on-change="(values) => onChange(item.fieldName, values)"
        />
        <DatePicker
          v-else-if="item.type === QUERY_TYPE.DATE"
          type="date"
          placement="bottom-end"
          :style="{ width: item.width || DEFAULT_WIDTH }"
          :options="item.options"
          :value="queryData[item.fieldName]"
          :placeholder="`请选择${item.name}`"
          @on-change="(value) => onChange(item.fieldName, value)"
        />
        <DatePicker
          v-else-if="item.type === QUERY_TYPE.MONTH"
          type="month"
          placement="bottom-end"
          :style="{ width: item.width || DEFAULT_WIDTH }"
          :options="item.options"
          :value="queryData[item.fieldName]"
          :placeholder="`请选择${item.name}`"
          @on-change="(value) => onChange(item.fieldName, value)"
        />
        <slot
          v-else-if="item.type === QUERY_TYPE.SLOT"
          :name="item.slot || item.fieldName"
        />
      </div>
    </div>
    <Button
      v-if="searchButtonConfig.visible"
      :type="searchButtonConfig.type || 'primary'"
      @click="onSearch"
    >
      {{ searchButtonConfig.text || "搜索" }}
    </Button>
  </div>
</template>

<script>
import { QUERY_TYPE, DEFAULT_WIDTH } from "./constants";

export default {
  name: "query-bar",
  model: {
    prop: "queryData",
    event: "onChange",
  },
  props: {
    queryConfig: {
      type: Array,
      default: () => [],
    },
    queryData: {
      type: Object,
      default: () => ({}),
    },
    searchButtonConfig: {
      type: Object,
      default: () => ({
        visible: true,
        type: "primary",
        text: "搜索",
      }),
    },
  },
  data() {
    return {
      QUERY_TYPE,
      DEFAULT_WIDTH,
    };
  },
  methods: {
    onChange(fieldName, value) {
      const queryDataCopy = { ...this.queryData };
      queryDataCopy[fieldName] = value;
      queryDataCopy.page = 1;
      this.$emit("onChange", queryDataCopy, fieldName);
    },
    onSearch() {
      this.$emit("onSearch", this.queryData);
    },
  },
};
</script>

<style lang="less" scoped>
.query-bar {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-end;
  margin-bottom: @spacingMD;
}
.main-content {
  // flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -@spacingMD;
  .query-item {
    display: inherit;
    align-items: center;
    margin-right: @spacingMD;
    margin-bottom: @spacingMD;
    .query-title {
      position: relative;
      min-width: fit-content;
      margin-right: @spacingMD;
      font-weight: 800;
      &::after {
        content: ":";
        position: absolute;
        right: -(@spacingMD / 2);
        top: 0;
      }
    }
    > *:last-child {
      flex: 1 1 auto;
    }
  }
}
</style>
