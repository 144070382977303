<template>
  <Table
    stripe
    :load-data="loadData"
    :row-key="rowKey"
    :columns="realColumns"
    :data="data"
    @on-selection-change="(selection) => $emit('onSelect', selection)">
    <template
      v-if="indexVisible"
      slot="index"
      slot-scope="{ index }">
      {{calcIndex(index)}}
    </template>
    <template
      :slot="slotName"
      slot-scope="{ row, index }"
      v-for="slotName in slotsName">
      <slot
        :name="slotName"
        :row="row"
        :index="index" />
    </template>
  </Table>
</template>

<script>
import mixin from './mixin';
import appPaginationMixin from '../app-pagination/mixin';

export default {
  name: 'app-table',
  mixins: [
    mixin,
    appPaginationMixin,
  ],
  computed: {
    /**
     * @override
     * @return {string[]}
     */
    permissions() {
      return Object.keys(this.$route.meta?.buttons || {});
    },
    actionVisible() {
      if (
        this.permissions.length === 0
        || this.allowedPermissions?.length === 0
      ) {
        return false;
      }

      for (let index = 0; index < this.permissions.length; index += 1) {
        if (this.allowedPermissions.includes(this.permissions[index])) {
          return true;
        }
      }

      return false;
    },
    realColumns() {
      const columnsDup = Array.isArray(this.columns)
        ? this.columns.map((column) => ({ ...column, tooltip: column.tooltip || true })) : [];

      if (this.indexVisible) {
        columnsDup.unshift({
          title: '序号',
          key: 'index',
          slot: 'index',
          fixed: 'left',
          width: 80,
          tooltip: true,
        });
      }

      if (this.selectable) {
        columnsDup.unshift({
          type: 'selection',
          fixed: 'left',
          width: 60,
          align: 'center',
        });
      }

      if (this.rowKey) {
        columnsDup.unshift({
          title: '操作',
          key: 'operation',
          fixed: 'left',
          width: 80,
          align: 'center',
          tree: true,
        });
      }

      if (!this.actionVisible) {
        const index = columnsDup.findIndex((column) => column.slot === 'action');

        if (index !== -1) {
          columnsDup.splice(index, 1);
        }
      }

      return columnsDup;
    },
  },
  methods: {
    calcIndex(index = 0) {
      const { page = 1, limit = 1 } = this.pageConfig || {};

      return (index + 1) + (page - 1) * limit;
    },
  },
};
</script>

<style scoped>

</style>
