import axios from 'axios';
import { Message } from 'view-design';
import './vuex';
// eslint-disable-next-line import/no-cycle
import store from '../stores/store';
import './router';
import router from '../routes/router';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const config = {
//   baseURL: 'http://192.168.0.87', // 测试服
//   // baseURL: 'http://127.0.0.1:8001', // 本地服
//   // baseURL: 'http://47.95.115.150', // 测试服
//   // baseURL: 'http://39.105.5.236', // 正式服
//   // timeout: 60 * 1000, // Timeout
//   // withCredentials: true, // Check cross-site Access-Control
// };

const config = {
  baseURL: process.env.VUE_APP_BASEURL,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: false, // Check cross-site Access-Control
};

const axiosInstance = axios.create(config);
const autoShowErrorMap = new Map();

axiosInstance.interceptors.request.use(
  (axiosRequestConfig) => {
    const {
      url, baseURL, headers, method, data,
    } = axiosRequestConfig;

    if (url.match(/\/page$/)) {
      store.commit('setTableLoading', true);
    }

    const addSlash = url[0] !== '/';
    const key = `${baseURL}${addSlash ? '/' : ''}${url}`;
    const {
      skipError = false,
      skipSuccess = false,
      skipErrorCatch = false,
      desc = '',
    } = headers.meta || {};

    autoShowErrorMap.set(key, {
      showError: !skipError,
      showSuccess: !skipSuccess,
      skipErrorCatch,
      desc,
    });

    if (method?.toLowerCase() === 'post' && data) {
      const skipFilterFields = headers.meta?.skipFilterFields || [];
      const filterData = {};
      Object.keys(data).forEach((dataKey) => {
        // 移除值为空串或为all的字段
        if (
          !skipFilterFields.includes(dataKey)
          && !!data[dataKey]?.toString()
          && data[dataKey]?.toString() !== 'NaN'
          && data[dataKey] !== 'all'
        ) {
          filterData[dataKey] = data[dataKey];
        }
      });
      // eslint-disable-next-line no-param-reassign
      axiosRequestConfig.data = filterData;
    }

    // eslint-disable-next-line no-param-reassign
    delete axiosRequestConfig.headers.meta;

    if (store.getters.token) {
      // eslint-disable-next-line no-param-reassign
      axiosRequestConfig.headers.Authorization = store.getters.token;
    }

    return axiosRequestConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const {
      showSuccess = false,
      showError = false,
      skipErrorCatch = false,
      desc = '',
    } = autoShowErrorMap.get(response.config.url) || {};

    if (response.config.url.match(/\/page$/)) {
      store.commit('setTableLoading', false);
    }

    if (skipErrorCatch) {
      return Promise.resolve(response);
    }

    if (showError && response.data.status !== 0) {
      Message.error({ content: `${desc}${desc ? '请求失败: ' : ''}${response.data.msg || '未知错误！'}`, duration: 10, closable: true });
    }

    if (showSuccess && response.data.status === 0) {
      Message.success({ content: `${desc}成功!`, duration: 10, closable: true });
    }

    if (response.data.status !== 0) {
      return Promise.reject(response.data);
    }

    return Promise.resolve(response.data.data);
  },
  (error) => {
    if (error.response.status === 401) {
      store.commit('removeToken');
      return router.push('/');
    }

    Message.error(error.response?.data || '网络错误');
    return Promise.reject(error);
  },

);

export default axiosInstance;
