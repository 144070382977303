import { PAGE_SIZE_OPTS } from './constants';

export default {
  props: {
    pageConfig: {
      type: Object,
      default: () => ({
        count: 0,
        page: 1,
        limit: 10,
        pageSizeOptions: PAGE_SIZE_OPTS,
      }),
    },
  },
};
