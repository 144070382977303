<template>
  <div class="app-block-flex">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'app-block-flex',
};
</script>

<style lang="less" scoped>
.app-block-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: @spacingMD;
  > * {
    margin-bottom: 0 !important;
  }
}
</style>
