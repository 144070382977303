export const USER_TYPE = {
  ADMIN: 'ADMIN',
  CHANNEL: 'CHANNEL',
  OUTLET: 'OUTLET',
};

export const USER_TYPE_TEXT = {
  [USER_TYPE.ADMIN]: '运营',
  [USER_TYPE.CHANNEL]: '渠道',
  [USER_TYPE.OUTLET]: '门店',
};

export const LOGIN_TYPE = {
  [USER_TYPE.ADMIN]: 'sys',
  [USER_TYPE.CHANNEL]: 'channel',
  [USER_TYPE.OUTLET]: 'store',
};
