import Vue from 'vue';
import VDistpicker from 'v-distpicker';
import App from './App.vue';

import './plugins/axios';
import './plugins/iview';
import './plugins/element';
import './plugins/app-ui';
import './plugins/router';
import './plugins/vuex';
import router from './routes/router';
import guards from './routes/guards';
import store from './stores/store';
import permissionMixin from './mixins/permission';
import RouterUtil from './utils/router.util';
import './assets/icon/iconfont.css';

Vue.component('v-distpicker', VDistpicker);
Vue.config.productionTip = false;
Vue.mixin(permissionMixin);

RouterUtil.loadGuards(guards, { router });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
