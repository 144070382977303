import VueRouter from 'vue-router';
import routerMap from '@/routes/router.map';
import router, { routes } from '@/routes/router';

class RouterUtil {
  static menus = {};

  static createRouter() {
    return new VueRouter({ routes });
  }

  static resetRouter() {
    router.matcher = this.createRouter().matcher;
  }

  /**
   * 同步动态路由
   * @param routersConfig
   * @param parentName
   */
  static syncRouters(routersConfig = [], parentName = 'root') {
    for (let index = 0; index < routersConfig.length; index += 1) {
      const routerConfig = routersConfig[index];
      const {
        menu_list: subMenus = [],
        button_list: buttons = [],
        permi: key = '',
        name = '',
        id = NaN,
      } = routerConfig;
      let vRoute = routerMap[key] ? {
        ...routerMap[key],
        meta: {
          ...routerMap[key].meta, id, name, buttons: RouterUtil.convertButtons(buttons),
        },
      } : null;

      // 没有特定页面的路由，一般是含有子页面的路由，当前路由由一个空组件组成
      if (!vRoute && subMenus.length !== 0) {
        // 防止意外的key，例如：account:subAccount
        const [realKey] = key.split(':');
        vRoute = {
          path: realKey,
          name: realKey,
          meta: {
            id,
            name,
            buttons: RouterUtil.convertButtons(buttons),
          },
          component: () => import('@/layouts/empty-layout'),
        };
        router.addRoute(parentName, vRoute);
      } else if (parentName && vRoute) {
        router.addRoute(parentName, vRoute);
      }

      if (subMenus.length !== 0) {
        RouterUtil.syncRouters(subMenus, vRoute?.name);
      }
    }
  }

  /**
   * 获取默认重定向地址
   * @return {*|string}
   */
  static getDefaultRedirectUrl() {
    const pRoutes = router
      .getRoutes()
      .map(({ path = '' }) => path.split('/'));

    return RouterUtil.concatUrl('root', pRoutes, 2, 'root');
  }

  /**
   * 拼接地址
   * @private
   * @param fullPathName
   * @param pRoutes
   * @param currentIndex
   * @param parentDir
   * @return {string|*}
   */
  static concatUrl(fullPathName = '', pRoutes = [], currentIndex = 2, parentDir = '') {
    const sub = pRoutes
      .filter((pRoute) => pRoute.length > currentIndex
        && pRoute[currentIndex - 1] === parentDir)[0];
    if (sub) {
      return RouterUtil.concatUrl(`${fullPathName}/${sub[currentIndex]}`, pRoutes, currentIndex + 1, sub[currentIndex]);
    }

    return `/${fullPathName}`;
  }

  /**
   * 转换当前页面按钮权限
   * @private
   * @param buttons
   * @return {{}}
   */
  static convertButtons(buttons = []) {
    const cButtons = {};

    buttons.forEach((button) => {
      const { permi: key } = button;
      cButtons[key] = button;
    });

    return cButtons;
  }

  /**
   * 转换动态菜单
   * @param routersConfig
   * @param parentName
   * @return {{}}
   */
  static convertMenus(routersConfig = [], parentName = 'root') {
    const cMenus = {};
    for (let index = 0; index < routersConfig.length; index += 1) {
      const routerConfig = routersConfig[index];
      const {
        menu_list: subMenus = [],
        button_list: buttons = [],
        permi: key = '',
        name = '',
        id = NaN,
      } = routerConfig;
      let vRoute = routerMap[key] ? {
        ...routerMap[key],
        meta: {
          ...routerMap[key].meta, id, name, buttons: RouterUtil.convertButtons(buttons),
        },
      } : null;

      // 没有特定页面的路由，一般是含有子页面的路由，当前路由由一个空组件组成
      if (!vRoute && subMenus.length !== 0) {
        // 防止意外的key，例如：account:subAccount
        const [realKey] = key.split(':');
        vRoute = {
          path: realKey,
          name: realKey,
          meta: {
            id,
            name,
            buttons: RouterUtil.convertButtons(buttons),
          },
          component: () => import('@/layouts/empty-layout'),
        };
        cMenus[vRoute.name] = vRoute;
      } else if (parentName && vRoute) {
        cMenus[vRoute.name] = vRoute;
      }

      if (subMenus.length !== 0) {
        cMenus[vRoute.name].children = RouterUtil.convertMenus(subMenus, vRoute?.name);
      }
    }

    return cMenus;
  }

  /**
   * 加载路由守卫
   * @param guards
   * @param options
   */
  static loadGuards(guards, options) {
    const { beforeEach = [], afterEach = [] } = guards || {};
    // eslint-disable-next-line no-shadow
    const { router, ...realOptions } = options || {};
    beforeEach.forEach((guard) => {
      router.beforeEach((to, from, next) => guard(to, from, next, realOptions));
    });
    afterEach.forEach((guard) => {
      router.afterEach((to, from) => guard(to, from, realOptions));
    });
  }
}

export default RouterUtil;
