<template>
  <div class="app-data-table">
    <Spin size="large" fix v-if="loading" />
    <app-table
      :load-data="loadData"
      :row-key="rowKey"
      :selectable="selectable"
      :columns="columns"
      :data="data"
      :pageConfig="pageConfig"
      :allowed-permissions="allowedPermissions"
      @onSelect="(selection) => $emit('onSelect', selection)">
      <template
        :slot="slotName"
        slot-scope="{ row, index }"
        v-for="slotName in slotsName">
        <slot
          :name="slotName"
          :row="row"
          :index="index" />
      </template>
    </app-table>
    <app-pagination
      :pageConfig="pageConfig"
      @onChange="(cfg) => $emit('onPageConfigChange', cfg)" />
  </div>
</template>

<script>
import AppTable from '../app-table';
import appTableMixin from '../app-table/mixin';
import AppPagination from '../app-pagination';
import appPaginationMixin from '../app-pagination/mixin';

export default {
  name: 'app-data-table',
  mixins: [
    appTableMixin,
    appPaginationMixin,
  ],
  model: {
    prop: 'pageConfig',
    event: 'onPageConfigChange',
  },
  components: {
    AppTable,
    AppPagination,
  },
  computed: {
    loading() {
      return this.$store.getters.tableLoading;
    },
  },
};
</script>

<style scoped>
.app-data-table {
  position: relative;
}
</style>
