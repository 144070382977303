<template>
  <Modal
    class-name="app-modal"
    :title="title"
    :value="value"
    :width="width"
    :mask-closable="false"
    :footer-hide="footerHide"
    @on-cancel="onCancel">
    <template
      v-if="$slots.header"
      slot="header">
      <slot name="header" />
    </template>
    <template slot="footer">
      <slot name="footer">
        <Button
          type="primary"
          @click="onOk">
          {{ okText }}
        </Button>
        <Button @click="onCancel">
          {{ cancelText }}
        </Button>
      </slot>
    </template>
    <slot />
  </Modal>
</template>

<script>
export default {
  name: 'app-modal',
  model: {
    prop: 'value',
    event: 'on-cancel',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 416,
    },
    okWithCloseModal: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: '确认',
    },
    cancelText: {
      type: String,
      default: '取消',
    },
    footerHide: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onOk() {
      this.$emit('on-ok');

      if (this.okWithCloseModal) {
        this.onCancel();
      }
    },
    onCancel() {
      this.$emit('on-cancel');
    },
  },
};
</script>
