export const PRODUCT_TYPE = {
  // 青鸟
  PV3_QN: 10,
  // 威萌
  PV4_WM: 20,
  // 视诺星
  PV5_SNX: 30,
};

export const PRODUCT_CATEGORY = {
  // 家庭设备
  HOME: 10,
  // 门店设备
  STORE: 20,
  // 均可
  ALL: 30,
};

export const PRODUCT_PAD_VERSION_FLAG_TYPE = {
  CLOSE: 0,
  OPEN: 1,
  EXPIRE: 2,
  PERMANENT: 3,
};

export const PRODUCT_SALE_TYPE = {
  FOR_ALL: 1,
  FOR_LEASE: 2,
  FOR_TRY_OUT: 3,
};
